
























































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator"
import ProductListComponent from "@/components/ProductListComponent.vue"
import ImageComponent from "@/components/ImageComponent.vue"
import Product from "@/models/Product"
import ProductService from "@/services/ProductService"
import CartModule from "@/store/CartModule"
import CartTool from "@/services/tool/CartTool"
import { getModule } from "vuex-module-decorators"
import LangModule from "@/store/LangModule"
import SnackbarModule from "@/store/SnackbarModule"
import SessionModule from "@/store/SessionModule"
import QuantitySelectorComponent from "@/components/QuantitySelectorComponent.vue"
import Dialog from "@/models/vue/Dialog"
import DialogModule from "@/store/DialogModule"
import LoginService from "@/services/LoginService"

@Component
export default class LegalWarningView extends Vue {

	lang: any = getModule(LangModule).lang
}
